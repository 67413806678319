import React, { useEffect, useState, Fragment } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import moment from "moment";

import TaskService from "src/services/task.service";
import InviteService from "src/services/invite.service";
import UpdateService from "src/services/update.service";
import UserService from "src/services/user.service";
import PackageDropdown from "./components/PackageDropdown";
import ActivityDropdown from "./components/ActivityDropdown";
import CycleDropdown from "./components/CycleDropdown";
import { formatDate } from "src/utils/common";
import RedDot from "src/components/dots/RedDot";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};

const ActionHistory = () => {
  const currentUser = UserService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [filteredActivityLogs, setFilteredActivityLogs] = useState([]);

  //filter
  const [packageArray, setPackageArray] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [taskArray, setTaskArray] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  const [cycleArray, setCycleArray] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState(null);

  useEffect(() => {
    const runAsyncFunc = async () => {
      setLoading(true);
      await getAllPackages();
      setLoading(false);
    };
    runAsyncFunc();
  }, []);

  const getAllPackages = async () => {
    try {
      let response = await InviteService.getAcceptedAll(currentUser.project.id);
      let temp = [{ name: "All", code: "All", userId: -1 }];
      for (let invite of response.data) {
        temp.push({ code: invite.code, name: invite.invited.stakeholder.name, userId: invite.invited.id });
      }
      setSelectedPackage(temp[0]);
      setPackageArray(temp);
    } catch (err) {
      console.log("Fetching invites error: ", err);
    }
  };

  useEffect(() => {
    if (selectedPackage) {
      TaskService.getAllTasksByPackage(selectedPackage.code, currentUser.project.id)
        .then((response) => {
          let temp = [{ name: "All", taskId: -1 }];
          for (let task of response.data) {
            temp.push({ name: task.name, taskId: task.id });
          }
          setSelectedTask(temp[0]);
          setTaskArray(temp);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });

      UpdateService.getActivityLogsForPackage(selectedPackage.code, currentUser.project.id)
        .then((response) => {
          setActivityLogs(response.data);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });
    }
  }, [selectedPackage]);

  useEffect(() => {
    if (selectedTask) {
      if (selectedTask.name === "All") {
        UpdateService.getActivityLogsForPackage(selectedPackage.code, currentUser.project.id)
          .then((response) => {
            setActivityLogs(response.data);
          })
          .catch((error) => {
            console.log("get accepted invite error: ", error);
          });
      } else {
        UpdateService.getActivityLogsForTask(selectedTask.taskId)
          .then((response) => {
            setActivityLogs(response.data);
          })
          .catch((error) => {
            console.log("get activity logs for task error: ", error);
          });
      }
    }
  }, [selectedTask]);

  useEffect(() => {
    let groupedArray = [];
    for (let i = 1; i < cycleArray.length; i++) {
      groupedArray.push({
        cycle: cycleArray[i],
        logs: activityLogs.filter((item) => moment(item.date).isSame(moment(cycleArray[i]), "month")),
      });
    }
    setFilteredActivityLogs(groupedArray);
  }, [cycleArray]);

  useEffect(() => {
    setFilteredActivityLogs([]);
    if (activityLogs.length > 0) {
      let dates = activityLogs.map((item) => new Date(item.date));
      dates.sort((a, b) => moment(b).diff(moment(a)));

      const uniqueMonths = new Set();
      dates.forEach((date) => {
        const formattedDate = date.toLocaleString("en-US", { month: "short", year: "numeric" });
        uniqueMonths.add(formattedDate);
      });
      const monthsList = Array.from(uniqueMonths);

      let dateArray = ["All"];
      for (let date of monthsList) {
        dateArray.push(date);
      }
      setCycleArray(dateArray);
      setSelectedCycle(dateArray[0]);
    }
  }, [activityLogs]);

  useEffect(() => {
    if (selectedCycle) {
      setFilteredActivityLogs([]);
      if (selectedCycle === "All") {
        let groupedArray = [];
        for (let i = 1; i < cycleArray.length; i++) {
          groupedArray.push({
            cycle: cycleArray[i],
            logs: activityLogs.filter((item) => moment(item.date).isSame(moment(cycleArray[i]), "month")),
          });
        }
        setFilteredActivityLogs(groupedArray);
      } else {
        let filtered = activityLogs.filter((item) => moment(item.date).isSame(moment(selectedCycle), "month"));
        let groupedArray = [{ cycle: selectedCycle, logs: filtered }];
        setFilteredActivityLogs(groupedArray);
      }
    }
  }, [selectedCycle]);

  useEffect(() => {
    console.log("🚀 ~ ActionHistory ~ filteredActivityLogs:", filteredActivityLogs);
  }, [filteredActivityLogs]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="w-full h-full">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <PuffLoader
            color="#007DE5"
            loading={loading}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="w-full h-full pt-3 px-4 flex flex-col ">
          <div className="w-full flex items-center gap-3">
            <div className="w-[30%] max-w-[300px]">
              <PackageDropdown items={packageArray} selected={selectedPackage} selectChanged={setSelectedPackage} />
            </div>
            <div className="w-[30%] max-w-[300px]">
              <ActivityDropdown items={taskArray} selected={selectedTask} selectChanged={setSelectedTask} />
            </div>
            {cycleArray.length > 0 && (
              <div className="w-[30%] max-w-[300px]">
                <CycleDropdown items={cycleArray} selected={selectedCycle} setSelected={setSelectedCycle} />
              </div>
            )}
          </div>
          <div className="mt-8 flow-root ">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Cycle
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Activity
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Party
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Type
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Message
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Delay (Days)
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Delay Reason
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Previous Start
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Updated Start
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Previous Finish
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Updated Finish
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Previous % Progress
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Updated % Progress
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Delay Recovered
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Total Float Impact(Days)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {filteredActivityLogs.map((group) => (
                        <Fragment key={group.cycle}>
                          <tr className="border-t border-gray-200">
                            <th
                              scope="colgroup"
                              colSpan={16}
                              className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                            >
                              {group.cycle}
                            </th>
                          </tr>
                          {group.logs.map((log, index) => (
                            <tr
                              key={index}
                              className={classNames(index === 0 ? "border-gray-300" : "border-gray-200", "border-t")}
                            >
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {formatDate(log.date, "MMM DD, YYYY")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {formatDate(log.date, "MMM YYYY")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{log.activity}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{log.party}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{log.type}</td>
                              <td className="px-3 py-4 text-sm text-gray-500 ">
                                <div className="w-[300px] word-break-break-word">{log.message}</div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.delayInDays && (
                                  <div className="w-full flex items-center justify-center gap-1">
                                    <RedDot />
                                    <span className="font-normal text-[14px] text-rose-400">{log.delayInDays}</span>
                                  </div>
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{log.delayReason}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.previousStart && formatDate(log.previousStart, "MMM DD, YYYY")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.updatedStart && formatDate(log.updatedStart, "MMM DD, YYYY")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.previousFinish && formatDate(log.previousFinish, "MMM DD, YYYY")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.updatedFinish && formatDate(log.updatedFinish, "MMM DD, YYYY")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.previousProgress}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.updatedProgress}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {log.delayRecovered}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{log.impact}</td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ActionHistory;
