import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "src/redux/slices/AuthSlice";
import HeaderReducer from "src/redux/slices/HeaderSlice";
import KeywordReducer from "src/redux/slices/KeywordSlice";
import TasksReducer from "src/redux/slices/TasksSlice";

export const store = configureStore({
  reducer: {
    Auth: AuthReducer,
    Header: HeaderReducer,
    Keyword: KeywordReducer,
    Tasks: TasksReducer,
  },
});
