import React, { useEffect, useState, forwardRef } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import BlueButton from "src/components/buttons/BlueButton";
import WhiteButton from "src/components/buttons/WhiteButton";
import CodeDropDown from "src/components/dropdown/CodeDropDown";
import EmailInput from "src/components/inputs/EmailInput";
import CodeService from "src/services/code.service";
import InviteService from "src/services/invite.service";
import UserService from "src/services/user.service";
import { toast } from "react-toastify";
import DateInput from "src/components/inputs/DateInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import TaskService from "src/services/task.service";
import UpdateService from "src/services/update.service";
import { formatDate, toLocalDate } from "src/utils/common";
import TextInput from "src/components/inputs/TextInput";
import NumberInput from "src/components/inputs/NumberInput";
import Delaycategory from "src/services/delaycategory.service";

const UpdateModal = ({ shown, onCancel, task, save, removeUpdate }) => {
  const user = UserService.getCurrentUser();

  // const [task, setTask] = useState(pTask);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [progress, setProgress] = useState(0);
  const [delayed, setDelayed] = useState(false);
  const [categoryObjects, setCategoryObjects] = useState([]);
  const [delayCategories, setDelayCategories] = useState([]);
  const [selectedDelayCategory, setSelectedDelayCategory] = useState(null);
  const [delayReason, setDelayReason] = useState("");

  useEffect(() => {
    Delaycategory.getAll()
      .then((response) => {
        setCategoryObjects(response.data);
        let temp = [];
        response.data.forEach((item) => {
          temp.push(item.name);
        });
        setDelayCategories(temp);
      })
      .catch((error) => {
        console.log("🚀 ~Delaycategory  getAll ~ error:", error);
      });
  }, []);

  useEffect(() => {
    if (task) {
      console.log("🚀 ~ useEffect ~ task:", task);
      setStartDate(moment.utc(task.startDate));
      setEndDate(moment.utc(task.endDate));
      setProgress(task.progress);
    }
  }, [task, shown]);

  const handleSave = () => {
    let update = {
      task: task.id,
      startDate: task.startDate,
      endDate: task.endDate,
      progress: task.progress,
    };
    let updated = false;

    if (!task.startDateConfirmed) {
      if (moment(startDate, "YYYY-MM-DD").diff(moment(task.startDate, "YYYY-MM-DD"), "days") !== 0) {
        update["updatedStartDate"] = startDate;
        updated = true;
      }
    }

    if (!task.endDateConfirmed) {
      if (moment(endDate, "YYYY-MM-DD").diff(moment(task.endDate, "YYYY-MM-DD"), "days") !== 0) {
        update["updatedEndDate"] = endDate;
        updated = true;
      }
    }

    if (task.startDateConfirmed && progress !== task.progress) {
      update["updatedProgress"] = progress;
      updated = true;
    }

    if (delayed) {
      update["delayCategory"] = selectedDelayCategory;
      update["delayReason"] = delayReason;
      updated = true;
    }

    if (updated) {
      save(update);
    } else {
      //nothing changed so remove the existing update
      removeUpdate(task);
      onCancel();
    }
  };

  useEffect(() => {
    if (task) {
      let delay = moment.utc(endDate).startOf("day").diff(moment.utc(task.endDate).startOf("day"), "days") > 0;
      if (delay) {
        setDelayed(true);
      } else {
        setDelayed(false);
      }
    }

    // if (task && moment.utc(startDate).startOf("day").diff(moment.utc(task.endDate).startOf("day"), "days") > 0) {
    //   setDelayed(true);
    // } else {
    //   setDelayed(false);
    // }
  }, [endDate]);

  const StartInput = forwardRef(({ value, onClick }, ref) => <DateInput text={value} onClick={onClick} />);
  const EndInput = forwardRef(({ value, onClick }, ref) => <DateInput text={value} onClick={onClick} />);

  return (
    shown && (
      <Dialog open={shown} onClose={onCancel} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="w-full bg-white rounded-lg">
                <div className="w-full h-[48px] bg-gray-50 flex items-center justify-center ">
                  <span className="font-ChivoMonoMedium text-[20px]">Update Activity</span>
                </div>
                <div className="w-full px-[8px] py-[20px] bg-white ">
                  {!task.startDateConfirmed && (
                    <div>
                      <p className="font-ChivoMonoMedium text-[12px] text-[#333333] pb-2">START</p>
                      <DatePicker
                        selected={startDate && toLocalDate(startDate)}
                        onChange={(date) => {
                          const string = moment(date).format("YYYY-MM-DD");
                          setStartDate(moment.utc(string));
                        }}
                        customInput={<StartInput />}
                      />
                    </div>
                  )}

                  {!task.endDateConfirmed && (
                    <div>
                      <p className="font-ChivoMonoMedium text-[12px] text-[#333333] mt-4 pb-2">FINISH</p>
                      <DatePicker
                        selected={endDate && toLocalDate(endDate)}
                        onChange={(date) => {
                          const string = moment(date).format("YYYY-MM-DD");
                          setEndDate(moment.utc(string));
                        }}
                        customInput={<EndInput />}
                      />
                    </div>
                  )}

                  {task.startDateConfirmed && (
                    <div>
                      <p className="font-ChivoMonoMedium text-[12px] text-[#333333] mt-4 pb-2">PROGRESS %</p>
                      <NumberInput value={progress} onTextChanged={(value) => setProgress(value)} />
                    </div>
                  )}

                  {delayed && (
                    <div>
                      <p className="font-ChivoMonoMedium text-[12px] text-[#333333] mt-4 pb-2">DELAY REASON</p>
                      <CodeDropDown
                        options={delayCategories}
                        onSelect={(value) => {
                          let categoryId = categoryObjects.find((c) => c.name === value).id;
                          setSelectedDelayCategory(categoryId);
                        }}
                        placeholder="Select category"
                      />
                      {selectedDelayCategory >= 0 && (
                        <div className="mt-2">
                          <TextInput onTextChanged={(text) => setDelayReason(text)} placeholder="Enter reason" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="w-full h-[50px] flex items-center justify-between gap-[12px] pt-3 mt-7 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <WhiteButton title="CANCEL" onClick={onCancel} />
                  <BlueButton title="SAVE" onClick={handleSave} />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    )
  );
};

export default UpdateModal;
