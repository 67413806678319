import moment from "moment";

export const getImageUrl = (imagePath) => {
  return process.env.PUBLIC_URL + imagePath;
};

export const isAuthenticatedWithToken = () => {
  const token = localStorage.getItem("accessToken"); // or sessionStorage.getItem('jwtToken');
  if (token) {
    return true;
  }
  return false;
};

export const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return regex.test(password);
};

// export const API_URL = "https://updater.instaplan.io";
export const API_URL =
  process.env.REACT_APP_ENV == "production" ? "https://updater.instaplan.io" : "http://localhost:5000";

export const MSConfig = {
  client_id: "460dc147-09c8-4d7d-bdba-eba82fb6008c",
  client_secret: "9WG8Q~DuWf2xLEwcG8TR8T9Xn7KLE0Xk85rOxdcD",
  tenant_id: "3fc45962-584a-4f3b-9813-ee0282ea6b8c",
  redirect_uri:
    process.env.REACT_APP_ENV == "production"
      ? "https://updater.instaplan.io/auth/connect-msproject"
      : "http://localhost:3000/auth/connect-msproject",
};

export const formatDate = (date, formatString = "MM/DD/YYYY") => {
  return moment.utc(date).format(formatString);
};

export const toLocalDate = (date) => {
  const formattedString = moment.utc(date).format("YYYY-MM-DD");
  return moment(formattedString, "YYYY-MM-DD").toDate();
};
