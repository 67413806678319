const WhiteButton = ({ title, onClick, fontSize = 16 }) => {
  return (
    <div
      onClick={onClick}
      className="w-full h-[32px] bg-[#FAFAFD] rounded-[4px] flex items-center justify-center cursor-pointer  shadow-md border-[1px] border-[#ced0dc] "
    >
      <span className="font-medium text-[#606060]" style={{ fontSize: fontSize }}>
        {title}
      </span>
    </div>
  );
};

export default WhiteButton;
