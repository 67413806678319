import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
  invites: [],
};

export const TasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setInvites: (state, action) => {
      state.invites = action.payload;
    },
  },
});

export const { setTasks, setInvites } = TasksSlice.actions;

export default TasksSlice.reducer;
