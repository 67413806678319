import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const getAll = () => {
  return api.get("/api/user/getAll");

  // const currentUser = getCurrentUser();
  // return api.get("/api/user/getAll", {
  //   params: { role: currentUser.roles[0].name },
  // });
};

const getAllUsers = (roleName) => {
  return api.get("/api/user/getAll", {
    params: { role: roleName },
  });
};

const deleteUser = (id) => {
  return api.post("/api/user/delete", { userId: id });
};

const updateUsers = (userData) => {
  return api.post("/api/user/update_users", { user_data: userData });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const saveCurrentUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const updateUser = (user) => {
  return api.post("/api/user/update_user", { user });
};

const uploadAvatar = (file, id) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", file);

  return api.post("/api/user/file", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const getUsers = (ids) => {
  return api.post("/api/user/get_users", { ids });
};
const getUsersByRole = (role) => {
  return api.get("/api/user/getusersbyrole", { params: { role } });
};

const UserService = {
  getAll,
  getAllUsers,
  deleteUser,
  updateUsers,
  getCurrentUser,
  uploadAvatar,
  saveCurrentUser,
  updateUser,
  getUsers,
  getUsersByRole,
};

export default UserService;
