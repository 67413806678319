import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BlueButton from "src/components/buttons/BlueButton";
import PasswordInput from "src/components/inputs/PasswordInput";
import AuthService from "src/services/auth.service";
import UserService from "src/services/user.service";
import { getImageUrl, validatePassword } from "src/utils/common";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(true);

  const submit = () => {
    if (!validatePassword(password)) {
      setIsValid(false);
      return;
    }
    if (password !== confirmPassword) {
      toast("Passwords doesn't match");
      return;
    }

    const user = UserService.getCurrentUser();

    AuthService.resetPassword(user.email, password)
      .then(function (response) {
        if (response.status == 200) {
          navigate("/auth/create-account");
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center relative ">
      <img src={getImageUrl("/images/auth/bg.svg")} className="w-screen h-screen absolute left-0 top-0 object-cover" />

      <div className="bg-[#E5E7F488] w-[332px] rounded-md z-10 px-[16px] py-[32px] flex items-center flex-col">
        <img src={getImageUrl("/images/common/logo.png")} className="w-[34px] h-[34px]" />
        <span className="text-[#333333] text-[24px] font-bold mt-[8px] ">Welcome to Instaplan</span>

        <p className="w-full text-[#333333] text-[12px] font-bold mt-[16px] mb-[5px] ">PASSWORD</p>
        <PasswordInput onPasswordChanged={(value) => setPassword(value)} />
        <div className="h-[16px]" />
        {!isValid && <ErrorMessage />}
        <p className="w-full text-[#333333] text-[12px] font-bold mt-[16px] mb-[5px]">CONFIRM PASSWORD</p>
        <PasswordInput onPasswordChanged={(value) => setConfirmPassword(value)} />

        <div className="w-full mt-[40px] mb-[8px]">
          <BlueButton title="RESET" onClick={submit} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

const ErrorMessage = () => (
  <div className="w-full border-[#8D8D8D] border-[2px] rounded-xl p-[10px] ">
    <p className="w-full text-[#333333] text-[12px] font-medium ">Your password must contain:</p>
    <p className="w-full text-[#333333] text-[12px] font-normal mt-[4px] ">&#x2022; at least 8 characters</p>
    <p className="w-full text-[#333333] text-[12px] font-normal mt-[4px] ">&#x2022; at least 1 uppercase letter</p>
    <p className="w-full text-[#333333] text-[12px] font-normal mt-[4px] ">&#x2022; at least 1 lowercase letter</p>
    <p className="w-full text-[#333333] text-[12px] font-normal mt-[4px] ">&#x2022; at least 1 number</p>
  </div>
);
