import React, { useState, useEffect } from "react";

function NumberInput({ onTextChanged, value }) {
  // const [text, setText] = useState("");

  // useEffect(() => {}, []);

  const textChanged = (e) => {
    onTextChanged(e.target.value);
  };

  return (
    <div className="w-full h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center justify-center px-[15px] bg-white shadow-sm">
      <input
        value={value}
        type="number"
        onChange={textChanged}
        className="w-full text-inputEmailTitle text-[11px] font-medium"
      />
    </div>
  );
}

export default NumberInput;
