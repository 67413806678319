import React, { useEffect, useState } from "react";

import BlueButton from "src/components/buttons/BlueButton";
import EmailInput from "src/components/inputs/EmailInput";
import PasswordInput from "src/components/inputs/PasswordInput";
import TextInput from "src/components/inputs/TextInput";
import { MSConfig, getImageUrl } from "src/utils/common";
import { loginRequest } from "src/utils/msprojectconfig";
import { useNavigate, useLocation } from "react-router-dom";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import UserService from "src/services/user.service";
import axios from "axios";
import { toast } from "react-toastify";
import AuthService from "src/services/auth.service";
import TokenService from "src/services/token.service";

const ConnectMSProject = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  useEffect(() => {
    const callAsyncFunc = () => {
      getToken();
    };
    if (code) {
      callAsyncFunc();
    }
  }, [code]);

  const getToken = async () => {
    try {
      let res = await AuthService.getMSAccessToken(code);
      let sharepointAccessToken = res.data.access_token;
      let sharepointRefreshToken = res.data.refresh_token;

      const user = UserService.getCurrentUser();
      await UserService.updateUser({ id: user.id, planner: { sharepointAccessToken, sharepointRefreshToken } });
      TokenService.saveSharepointToken({ access: sharepointAccessToken, refresh: sharepointRefreshToken });
      navigate("/auth/welcome");
    } catch (error) {
      console.log("🚀 ~ getToken ~ error:", error);
    }
  };

  const handleLogin = async () => {
    let authUrl = `https://login.microsoftonline.com/${MSConfig.tenant_id}/oauth2/v2.0/authorize?client_id=${MSConfig.client_id}
    &response_type=code&redirect_uri=${MSConfig.redirect_uri}&response_mode=query
    &scope=offline_access%20openid%20profile`;

    window.location.href = authUrl;
    // let res = await axios.get(authUrl);
    // console.log("🚀 ~ handleLogin ~ res:", res);
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center relative ">
      <img src={getImageUrl("/images/auth/bg.svg")} className="w-screen h-screen absolute left-0 top-0 object-cover" />

      <div className="bg-[#E5E7F488] w-[332px] rounded-md z-10 px-[16px] py-[32px] flex items-center flex-col">
        <img src={getImageUrl("/images/common/logo.png")} className="w-[34px] h-[34px]" />
        <span className="text-[#333333] text-[14px] font-bold mt-[8px] ">Connect your Microsoft Project</span>
        {/* <p className="w-full text-[#333333] text-[12px] font-bold mt-[40px]">USERNAME</p>
        <TextInput onTextChanged={(value) => setUsername(value)} />

        <p className="w-full text-[#333333] text-[12px] font-bold mt-[16px] ">PASSWORD</p>
        <PasswordInput onPasswordChanged={(value) => setPassword(value)} />

        <p className="w-full text-[#333333] text-[12px] font-bold mt-[16px]">HOST</p>
        <TextInput onTextChanged={(value) => setHost(value)} /> */}

        <div className="w-full mt-[40px] mb-[8px]">
          <BlueButton title="CONNECT" onClick={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export default ConnectMSProject;
