import React, { useEffect, useState } from "react";
import moment from "moment";

import StakeholderDropDown from "./components/StakeholderDropdown";
import UpdateService from "src/services/update.service";
import UserService from "src/services/user.service";
import BarChart from "./components/BarChart";

const DelayByCycle = ({ stakeholderArray }) => {
  const currentUser = UserService.getCurrentUser();
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);
  const [barData, setBarData] = useState({ labels: [], data: [] });

  useEffect(() => {
    setSelectedStakeholder(stakeholderArray[0]);
  }, [stakeholderArray]);

  useEffect(() => {
    UpdateService.getAcceptedAll(currentUser.project.id)
      .then((response) => {
        delayBarChart(response.data);
      })
      .catch((error) => {
        console.log("get accepted invite error: ", error);
      });
  }, [selectedStakeholder]);

  const delayBarChart = (updatesData) => {
    let updates = [...updatesData];
    //filter updates according to selected stakeholder
    if (selectedStakeholder.code !== "All") {
      updates = updatesData.filter((update) => update.code === selectedStakeholder.code);
    }
    // Extract the min and max dates
    const minMaxDates = updates.reduce(
      (acc, curr) => {
        const currDate = curr.actionDate;
        if (!acc.min || currDate < acc.min) acc.min = currDate;
        if (!acc.max || currDate > acc.max) acc.max = currDate;
        return acc;
      },
      { min: null, max: null }
    );

    //get labels like 04/2024, 05/2024, .... 10/2024
    const labels = [];
    const start = moment(minMaxDates.min);
    const end = moment(minMaxDates.max);
    while (start.isSameOrBefore(end, "month")) {
      labels.push(start.format("MM/YYYY"));
      start.add(1, "month");
    }

    let data = [];
    for (let label of labels) {
      let totalImpact = 0;
      for (let update of updates) {
        if (moment(update.actionDate).isSame(moment(label, "MM/YYYY"), "month")) {
          totalImpact += update.impact;
        }
      }
      data.push(totalImpact);
    }

    setBarData({ labels, data });
  };

  return (
    <div>
      <div className="w-[300px] my-2">
        <StakeholderDropDown
          stakeholders={stakeholderArray}
          selectedStakeholder={selectedStakeholder}
          onSelect={setSelectedStakeholder}
        />
      </div>
      <div className="w-full flex gap-[20px] p-1 ">
        <div className="w-full">
          <div className="w-full mb-4 shadow ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden">
            <BarChart data={barData} title="Delay by Cycle" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelayByCycle;
