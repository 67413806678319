const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full h-full border-[1px] border-[#CED0DC] rounded-[12px] px-[2px] py-[2px] flex items-center justify-center shadow-md">
      <div className="w-full h-full">
        <div
          className="h-full bg-gradient-to-r from-[#0170CE] to-[#64B7FE] rounded-[11px]"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
