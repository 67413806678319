import { getImageUrl } from "src/utils/common";
import PuffLoader from "react-spinners/PuffLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};

const AcceptButton = ({ onClick, loading }) => {
  return (
    <div className=" relative">
      {loading ? (
        <PuffLoader
          color="green"
          loading={true}
          cssOverride={override}
          size={25}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <img
          onClick={onClick}
          src={getImageUrl("/images/updatedetail/accept.svg")}
          className="w-[24px] h-[24px] object-contain cursor-pointer"
        />
      )}
    </div>
  );
};

export default AcceptButton;
