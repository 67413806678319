import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, dl } from "@headlessui/react";
import moment from "moment";
import { formatDate } from "src/utils/common";
import RedDot from "./dots/RedDot";

import {
  CheckCircleIcon,
  XCircleIcon,
  ChatBubbleLeftEllipsisIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { PaperClipIcon, PaperAirplaneIcon, TableCellsIcon, PresentationChartLineIcon } from "@heroicons/react/20/solid";

const tabs = [
  { name: "Table", href: "#", icon: TableCellsIcon, current: true },
  { name: "Visual Timeline", href: "#", icon: PresentationChartLineIcon, current: false },
];

const ActivityLogs = ({ logs, shown, onCancel, send, isForPlanner }) => {
  const [message, setMessage] = useState("");
  const [firstSubmission, setFirstSubmission] = useState(null);
  const [approvedSubmission, setApprovedSubmission] = useState(null);

  useEffect(() => {
    setMessage("");
  }, [shown]);

  useEffect(() => {
    console.log("GGGGG: ", logs);
    let submissionArray = logs.filter((item) => item.type === "Submission");
    if (submissionArray.length > 0) {
      setFirstSubmission(submissionArray.pop());
    }

    setApprovedSubmission(logs.find((item) => item.type === "Approval"));
  }, [logs]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && message.length > 0) {
      send(message);
      setMessage("");
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [currentTab, setCurrentTab] = useState(tabs[0].name); // Set initial tab to "Team Members"

  const handleTabChange = (event) => {
    const selectedTabName = event.target.value;
    setCurrentTab(selectedTabName);
  };

  const renderContent = () => {
    switch (currentTab) {
      case "Table":
        return (
          <div className="w-full mt-1 px-4 max-h-[500px] overflow-y-auto overflow-x-hidden">
            <div className="flow-root mt-2">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8 ">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className=" divide-y divide-gray-300 ">
                      <thead className="bg-gray-50 overflow-x-hidden">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Date
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Party
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Type
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Interaction/Comment
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Delay (Days)
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 ">
                            Finish Planned Date
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Finish Date
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Total Float Impact (Days)
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {logs.map((log, index) => (
                          <tr key={index} className="w-full">
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div className="w-full flex items-center justify-center">
                                <span className="font-normal text-[12px] text-[#333333]">
                                  {formatDate(log.date, "MMM DD, YYYY")}
                                </span>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div className="w-full flex items-center justify-center">
                                <span className="font-normal text-[12px] text-[#333333]">{log.party}</span>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div className="w-full flex items-center justify-center">
                                <span className="font-normal text-[12px] text-[#333333]">{log.type}</span>
                              </div>
                            </td>
                            <td className="py-2 pl-1 pr-1 text-sm font-medium text-gray-900">
                              {/* <div className="w-full flex items-center justify-center"> */}
                              <div className="font-normal text-[12px] text-[#333333] w-[230px] word-break-break-word">
                                {log.message}
                              </div>
                              {/* </div> */}
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {log.delayInDays && (
                                <div className="w-full flex items-center justify-center gap-1">
                                  <RedDot />
                                  <span className="font-normal text-[12px] text-rose-400">{log.delayInDays}</span>
                                </div>
                              )}
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div className="w-full flex items-center justify-center">
                                <span className="font-normal text-[12px] text-[#333333]">
                                  {formatDate(log.previousFinish)}
                                </span>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div className="w-full flex items-center justify-center">
                                <span className="font-normal text-[12px] text-[#333333]">
                                  {formatDate(log.updatedFinish)}
                                </span>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div className="w-full flex items-center justify-center">
                                <span className="font-normal text-[12px] text-[#333333]">{log.impact}</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "Visual Timeline":
        return (
          <div className="w-full mt-1 px-4 overflow-x-hidden">
            <div className="flow-root mt-2">
              <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8 ">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <ul role="list" className="bg-white overflow-y-auto space-y-6 max-w-7xl px-8 py-4 sm:px-8 lg:px-10">
                      {logs.map((log, index) => (
                        <li key={index} className="relative flex gap-x-4">
                          <div
                            className={classNames(
                              index === logs.length - 1 ? "h-6" : "-bottom-6",
                              "absolute left-0 top-0 flex w-6 justify-center"
                            )}
                          >
                            <div className="w-px bg-gray-200" />
                          </div>
                          {log.type === "Comment" ? (
                            <>
                              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                <ChatBubbleLeftEllipsisIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
                              </div>
                              <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                                <div className="flex justify-between gap-x-4">
                                  <div className="py-0.5 text-xs leading-5 text-gray-500">
                                    <span className="mr-0.5">
                                      <Fragment key={log.party}>
                                        <a
                                          href="#"
                                          className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                        >
                                          <svg
                                            viewBox="0 0 6 6"
                                            aria-hidden="true"
                                            className={`h-1.5 w-1.5 ${
                                              log.party === "Planner" ? "fill-primaryColor" : "fill-gray-300"
                                            }`}
                                          >
                                            <circle r={3} cx={3} cy={3} />
                                          </svg>
                                          {log.party}
                                        </a>{" "}
                                      </Fragment>{" "}
                                      Commented
                                    </span>
                                  </div>
                                  <time
                                    dateTime={log.date}
                                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                  >
                                    {formatDate(log.date, "MMM DD, YYYY")}
                                  </time>
                                </div>
                                <p className="text-xs leading-6 text-gray-500">{log.message}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                {log.type === "Approval" ? (
                                  <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-400" />
                                ) : log.type === "Rejection" ? (
                                  <XCircleIcon aria-hidden="true" className="h-6 w-6 text-rose-600" />
                                ) : log.type === "Submission" ? (
                                  <ArrowTrendingUpIcon aria-hidden="true" className="h-6 w-6 text-rose-400" />
                                ) : log.type === "Comment" ? (
                                  <ChatBubbleLeftEllipsisIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
                                ) : (
                                  <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                )}
                              </div>
                              <span className="mr-0.5"></span>
                              <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-medium text-gray-900">
                                  <Fragment key={log.party}>
                                    <a
                                      href="#"
                                      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                    >
                                      <svg
                                        viewBox="0 0 6 6"
                                        aria-hidden="true"
                                        className={`h-1.5 w-1.5 ${
                                          log.party === "Planner" ? "fill-primaryColor" : "fill-indigo-500"
                                        }`}
                                      >
                                        <circle r={3} cx={3} cy={3} />
                                      </svg>
                                      {log.party}
                                    </a>{" "}
                                  </Fragment>
                                  {log.type === "Approval"
                                    ? "Approved the update"
                                    : log.type === "Rejection"
                                    ? (
                                    < div className=" flex-auto py-0.5 text-xs leading-5 min-w-0 flex-1">
                                    <div>
                                      <div className="text-xs">
                                        Rejected the Update
                                      </div>
                                    </div>
                                    <div className="mt-2 text-xs text-gray-700">
                                      <div className="w-full flex items-left gap-1">
                                        {log.message}
                                      </div>
                                    </div>
                                  </div>
                                        )
                                    : ""}
                                </span>
                                {log.type === "Submission" ? (
                                  <div className="flex-auto py-0.5 text-xs leading-5 min-w-0 ">
                                    <div>
                                      <div className="text-xs">Submitted an Update</div>
                                    </div>
                                    <div className="mt-2 text-xs text-gray-700">
                                      <div className="w-full flex items-left gap-1">
                                        <p className="font-bold">Delay(Days):</p>
                                        <RedDot />
                                        <span className="font-normal text-[12px] text-rose-400">{log.delayInDays}</span>
                                      </div>
                                      <div className="w-full flex items-left gap-1">
                                        <p className="font-bold">Total Float Impact(Days):</p>
                                        <RedDot />
                                        <span className="font-normal text-[12px] text-rose-400">{log.impact}</span>
                                      </div>
                                      <div className="w-full flex items-left gap-1">
                                        <p className="font-bold">Original Finish Date:</p>
                                        <span className="font-normal text-[12px]">
                                          {formatDate(log.previousFinish)}
                                        </span>
                                      </div>
                                      <div className="w-full flex items-left gap-1">
                                        <p className="font-bold">New Finish Date:</p>
                                        <span className="font-normal text-[12px] text-rose-400">
                                          {formatDate(log.updatedFinish)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </p>
                              <time dateTime={log.date} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {formatDate(log.date, "MMM DD, YYYY")}
                              </time>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  return (
    shown && (
      <Dialog open={shown} onClose={onCancel} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="min-w-[900px] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all 
              data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 
              data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="max-h-full bg-bgColor flex flex-col ">
                <div className="w-full h-[48px] relative bg-white flex items-center justify-center ">
                  <span className="font-bold text-[18px] ">ACTION HISTORY</span>
                  <div onClick={onCancel} className="cursor-pointer absolute right-4">
                    <IoClose size={20} />
                  </div>
                </div>
                {isForPlanner && (
                  <div className="w-full px-4">
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      {firstSubmission && firstSubmission.delayReason && (
                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">Delay Reason</dt>
                          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
                            {firstSubmission.delayReason}
                          </dd>
                        </div>
                      )}
                      {approvedSubmission && approvedSubmission.delayRecovered && (
                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">Delay Recovered</dt>
                          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
                            {approvedSubmission.delayRecovered}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                )}
                <div className="px-4 mt-5">
                  <p className="font-semibold text-gray-900 ">{`Cycle: ${moment(new Date()).format("MMM YYYY")}`}</p>
                </div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    value={currentTab}
                    onChange={handleTabChange}
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name} value={tab.name}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="px-4">
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            aria-current={tab.name === currentTab ? "page" : undefined}
                            className={classNames(
                              tab.name === currentTab
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium"
                            )}
                            onClick={() => setCurrentTab(tab.name)}
                          >
                            <tab.icon
                              aria-hidden="true"
                              className={classNames(
                                tab.name === currentTab ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                            />
                            <span>{tab.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                {/* Tab Content Section */}
                <div className="mt-4">{renderContent()}</div>
                <div className="w-full px-4 py-2 ">
                  <div className="px-2 py-1.5 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-md overflow-hidden">
                    <textarea
                      value={message}
                      type="text"
                      onChange={(e) => setMessage(e.target.value)}
                      className="block w-full min-h-[50px] border-0 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 overflow-auto "
                      placeholder="Add your comments..."
                      // onKeyDown={handleKeyDown}
                    />
                    <div className="w-full flex items-center justify-between mt-2">
                      <div className="flex items-start space-x-5 mt-1">
                        <div className="flex items-center">
                          <button
                            type="button"
                            className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <PaperClipIcon aria-hidden="true" className="h-5 w-5" />
                            <span className="sr-only">Attach a file</span>
                          </button>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (message.length > 0) {
                            setMessage("");
                            send(message);
                          }
                        }}
                        type="submit"
                        className="rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <PaperAirplaneIcon aria-hidden="true" className="cursor-pointer h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    )
  );
};

export default ActivityLogs;
