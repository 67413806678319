import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TabBar from "./components/TabBar";

const UpdaterTopBar = () => {
  return (
    <div className="w-full bg-[#F5F5FB] flex flex-col">
      <p className="font-medium text-[24px] mb-3">Square Phillips 1 Residential Building</p>
      <div className="mb-4">
        <TabBar />
      </div>
    </div>
  );
};

export default UpdaterTopBar;
