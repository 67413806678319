import React, { useState } from "react";
import BlueButton from "src/components/buttons/BlueButton";
import { useNavigate } from "react-router-dom";
import EmailInput from "src/components/inputs/EmailInput";
import PasswordInput from "src/components/inputs/PasswordInput";
import TextInput from "src/components/inputs/TextInput";
import { getImageUrl } from "src/utils/common";
import UserService from "src/services/user.service";

const Welcome = () => {
  const navigate = useNavigate();

  const next = () => {
    const user = UserService.getCurrentUser();
    if (user.role.name === "Planner") {
      navigate("/planner/updater/overview");
    } else {
      navigate("/stakeholder/updates");
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center relative ">
      <img src={getImageUrl("/images/auth/bg.svg")} className="w-screen h-screen absolute left-0 top-0 object-cover" />

      <div className="bg-[#E5E7F488] w-[332px] rounded-md z-10 px-[16px] py-[32px] flex items-center flex-col">
        <img src={getImageUrl("/images/common/logo.png")} className="w-[34px] h-[34px]" />
        <span className="text-[#333333] text-[3 0px] font-bold mt-[8px] ">You are ready to go!</span>
        <div className="w-[233px] mt-[40px] mb-[8px]">
          <BlueButton title="START" onClick={next} />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
