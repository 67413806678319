import React from "react";
import { Outlet } from "react-router-dom";
import UpdaterTopBar from "src/pages/planner/updater/UpdaterTopBar";

const UpdaterLayout = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <UpdaterTopBar />
      <div className="w-full flex-auto overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default UpdaterLayout;
