import { createSlice } from "@reduxjs/toolkit";
import { isAuthenticatedWithToken } from "src/utils/common";

const initialState = {
  isAuthenticated: isAuthenticatedWithToken(),
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuthState } = AuthSlice.actions;

export default AuthSlice.reducer;
