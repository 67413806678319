import { getImageUrl } from "src/utils/common";

const WarningButton = ({ onClick }) => {
  return (
    <div className=" relative">
      <img
        onClick={onClick}
        src={getImageUrl("/images/common/warning.png")}
        className="w-[24px] h-[24px] object-contain cursor-pointer"
      />
    </div>
  );
};

export default WarningButton;
