import React, { useState, useEffect } from "react";
import { PiCalendarDots } from "react-icons/pi";

function DateInput({ text, onClick }) {
  return (
    <div
      onClick={onClick}
      className="w-[495px] h-[32px] border-[#CED0DC] border-[2px] rounded-[5px] flex items-center justify-center px-[15px] bg-white shadow-sm cursor-pointer"
    >
      <p className="w-full text-inputEmailTitle text-[11px] font-medium">{text}</p>
      <PiCalendarDots size={20} />
    </div>
  );
}

export default DateInput;
