import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "460dc147-09c8-4d7d-bdba-eba82fb6008c",
    authority: "https://login.microsoftonline.com/3fc45962-584a-4f3b-9813-ee0282ea6b8c",
    redirectUri: "http://localhost:3000", // or your production URL
  },
  cache: {
    cacheLocation: "sessionStorage", // 'sessionStorage' or 'localStorage'
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose, // Set the log level to verbose for detailed logs
    },
  },
};

export const loginRequest = {
  scopes: ["user.read"],
  // scopes: ["User.Read", "Sites.Read.All", "Tasks.Read"],
};
