import axios from "axios";
import UserService from "src/services/user.service";
import TokenService from "src/services/token.service";
import { API_URL } from "src/utils/common";
import { useDispatch } from "react-redux";
import { setAuthState } from "src/redux/slices/AuthSlice";
import { store } from "src/redux/store";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const user = UserService.getCurrentUser();
    if (user) {
      config.headers["business"] = user.business;
    }
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["Authorization"] = `Bearer ${token}`; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url == "/api/auth/refresh_token" && err.response) {
      if (err.response.status === 401) {
        // const dispatch = useDispatch();
        // dispatch(setAuthState(false));
        store.dispatch(setAuthState(false));
        return;
      }
    }

    if (originalConfig.url !== "/api/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        // originalConfig._retry = true;
        try {
          const rs = await instance.post(
            "/api/auth/refresh_token",
            {},
            {
              withCredentials: true,
            }
          );
          const accessToken = rs.data.accessToken.access_token;
          TokenService.updateLocalAccessToken(accessToken);
          instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          return instance(originalConfig);
        } catch (_error) {
          console.log("rs error: ", _error);
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
