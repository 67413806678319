import React, { useEffect, useState } from "react";
import TaskService from "src/services/task.service";
import UserService from "src/services/user.service";
import PackageComponent from "../components/PackageComponent";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const user = UserService.getCurrentUser();

  useEffect(() => {
    TaskService.getAllPackages(user.project.id)
      .then((res) => {
        setPackages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="w-full h-full p-1">
      <div className="w-full h-full bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <div className="w-full h-full overflow-auto">
          <div className="flex items-center h-[30px]">
            <div className="w-[30%] pl-6">
              <p className="font-bold text-[14px] text-[#606060] ">PACKAGE</p>
            </div>
            <div className="w-[50%] flex items-center pl-2">
              <p className="font-bold text-[14px] text-[#606060] ">ACTIVITY</p>
            </div>
            <div className="w-[20%] pl-6">
              <p className="font-bold text-[14px] text-[#606060] ">STAKEHOLDER</p>
            </div>
          </div>
          <div className="w-full border-t-[2px] border-gray-300">
            {packages.map((item, index) => (
              <div key={index}>
                <PackageComponent index={index} data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
