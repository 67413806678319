import { current } from "@reduxjs/toolkit";
import api from "./api";
import AuthService from "./auth.service";

const getAllPlannerTasks = (userId) => {
  return api.get("/api/task/get-planner-tasks", { params: { userId } });
};

const getAllStakeholderTasks = (userId) => {
  return api.get("/api/task/stakeholder-get-tasks", { params: { userId } });
};

const getAll = () => {
  return api.get("/api/task/get-all");
};

const create = () => {
  return api.get("/api/task/create");
};

const batchCreate = () => {
  return api.get("/api/task/batch-create");
};

const update = (data) => {
  return api.post("/api/task/update", data);
};

const getAllPackages = (projectId) => {
  return api.get("/api/task/get-all-packages", { params: { projectId } });
};

const getAllTasksByPackage = (code, projectId) => {
  return api.get("/api/task/get-tasks-by-package", { params: { code, projectId } });
};

const TaskService = {
  getAllPlannerTasks,
  getAll,
  create,
  batchCreate,
  getAllStakeholderTasks,
  update,
  getAllPackages,
  getAllTasksByPackage,
};

export default TaskService;
