import PuffLoader from "react-spinners/PuffLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#007DE5",
};
const BlueButton = ({ title, onClick, loading }) => {
  return (
    <div
      onClick={!loading ? onClick : null}
      // className="relative w-full h-[32px] bg-primaryColor rounded-[4px] flex items-center justify-center cursor-pointer shadow-md"
      className="relative flex items-center justify-center cursor-pointer w-full h-[32px] rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {loading ? (
        <div className="w-full h-full flex items-center justify-center absolute left-0 top-0 right-0 bottom-0 z-10">
          <PuffLoader
            color="white"
            loading={true}
            cssOverride={override}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <span className="font-semibold text-[16px] text-white">{title}</span>
      )}
    </div>
  );
};

export default BlueButton;
