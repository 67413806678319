import React, { useEffect, useState } from "react";

import TaskService from "src/services/task.service";
import UserService from "src/services/user.service";
import UpdateService from "src/services/update.service";
import PackageDropdown from "../actionhistory/components/PackageDropdown";
import ActivityDropdown from "../actionhistory/components/ActivityDropdown";
import { formatDate } from "src/utils/common";
import RedDot from "src/components/dots/RedDot";

const ActionHistoryTable = ({ packageArray }) => {
  const currentUser = UserService.getCurrentUser();
  const [activityLogs, setActivityLogs] = useState([]);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const [taskArray, setTaskArray] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    setSelectedPackage(packageArray[0]);
  }, [packageArray]);

  useEffect(() => {
    if (selectedPackage) {
      console.log("GGGGGGL: ", selectedPackage);
      TaskService.getAllTasksByPackage(selectedPackage.code, currentUser.project.id)
        .then((response) => {
          console.log("🚀🚀🚀🚀🚀:", response.data);
          let temp = [{ name: "All", taskId: -1 }];
          for (let task of response.data) {
            temp.push({ name: task.name, taskId: task.id });
          }
          setSelectedTask(temp[0]);
          setTaskArray(temp);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });
      UpdateService.getActivityLogsForPackage(selectedPackage.code, currentUser.project.id)
        .then((response) => {
          setActivityLogs(response.data);
        })
        .catch((error) => {
          console.log("get accepted invite error: ", error);
        });
    }
  }, [selectedPackage]);

  useEffect(() => {
    if (selectedTask) {
      if (selectedTask.name === "All") {
        UpdateService.getActivityLogsForPackage(selectedPackage.code, currentUser.project.id)
          .then((response) => {
            setActivityLogs(response.data);
          })
          .catch((error) => {
            console.log("get accepted invite error: ", error);
          });
      } else {
        UpdateService.getActivityLogsForTask(selectedTask.taskId)
          .then((response) => {
            console.log("🚀 ~ .then ~ response:", response);
            setActivityLogs(response.data);
          })
          .catch((error) => {
            console.log("get activity logs for task error: ", error);
          });
      }
    }
  }, [selectedTask]);

  return (
    <>
      <div className="w-full flex items-center gap-3">
        <div className="w-[30%] max-w-[300px]">
          <PackageDropdown items={packageArray} selected={selectedPackage} selectChanged={setSelectedPackage} />
        </div>
        <div className="w-[30%] max-w-[300px]">
          <ActivityDropdown items={taskArray} selected={selectedTask} selectChanged={setSelectedTask} />
        </div>
      </div>

      <div className="mt-1 px-1 overflow-y-auto overflow-x-hidden">
        <div className="flow-root mt-2">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block  py-2 align-middle sm:px-6 lg:px-8 ">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="divide-y divide-gray-300 ">
                  <thead className="bg-gray-50 overflow-x-hidden">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Party
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Interaction/Comment
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Delay (Days)
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 ">
                        Finish Planned Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Finish Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Total Float Impact (Days)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {activityLogs.map((log, index) => (
                      <tr key={index} className="w-full">
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="w-full flex items-center justify-center">
                            <span className="font-normal text-[12px] text-[#333333]">
                              {formatDate(log.date, "MMM DD, YYYY")}
                            </span>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="w-full flex items-center justify-center">
                            <span className="font-normal text-[12px] text-[#333333]">{log.party}</span>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="w-full flex items-center justify-center">
                            <span className="font-normal text-[12px] text-[#333333]">{log.type}</span>
                          </div>
                        </td>
                        <td className="py-2 pl-1 pr-1 text-sm font-medium text-gray-900">
                          <div className="font-normal text-[12px] text-[#333333] w-[230px] word-break-break-word">
                            {log.message}
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {log.delayInDays && (
                            <div className="w-full flex items-center justify-center gap-1">
                              <RedDot />
                              <span className="font-normal text-[12px] text-rose-400">{log.delayInDays}</span>
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="w-full flex items-center justify-center">
                            <span className="font-normal text-[12px] text-[#333333]">
                              {formatDate(log.updatedFinish)}
                            </span>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="w-full flex items-center justify-center">
                            <span className="font-normal text-[12px] text-[#333333]">
                              {formatDate(log.previousFinish)}
                            </span>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="w-full flex items-center justify-center">
                            <span className="font-normal text-[12px] text-[#333333]">{log.impact}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionHistoryTable;
