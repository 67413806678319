import { getImageUrl } from "src/utils/common";

const RejectButton = ({ onClick }) => {
  return (
    <img
      onClick={onClick}
      src={getImageUrl("/images/updatedetail/reject.svg")}
      className="w-[24px] h-[24px] object-contain cursor-pointer"
    />
  );
};

export default RejectButton;
